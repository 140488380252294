import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './ContactsPage.module.scss'
import Map from 'src/components/Map'
import { FormattedMessage } from 'react-intl'

class ContactsPage extends Component {
  render() {
    const { title, email, address } = this.props.data

    // const { locale } = this.props

    const geolocation = { latitude: 53.9054674, longitude: 27.51668799999993 }
    return (
      <div className={styles.root}>
        <div className={styles.inner}>
          <h1 className={styles.title}>{title}</h1>
          <Map geolocation={geolocation} style={{ height: '40vh' }} contactsMap={true} />

          <div className={styles.contacts}>
            <div>
              <a href={`mailto:${email}`}>
                <FormattedMessage id="home.writeUs" />
                <p>{email}</p>
              </a>
            </div>
            <div>
              <FormattedMessage id="home.ourAddress" />
              <p>{address}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

ContactsPage.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  html: PropTypes.string,
  locale: PropTypes.string,
}

export default ContactsPage
