import React from 'react'
import { graphql } from 'gatsby'
import Header from '../components/Header'
import Footer from '../components/Footer'
import PageHelmet from 'src/components/PageHelmet'
import ContactsPage from 'src/components/ContactsPage'
import withLayout from 'src/components/withLayout'
import { injectIntl } from 'react-intl'

class Contacts extends React.Component {
  render() {
    const {
      data: { datoCmsContactsPage: data },
      locale,
      changeLocale,
      intl,
    } = this.props

    return (
      <>
        <PageHelmet title={intl.formatMessage({ id: 'home.contacts' })} locale={locale} defer={false} />
        <Header full={true} locale={locale} changeLocale={changeLocale} />
        <ContactsPage data={data} locale={locale} />
        <Footer locale={locale} />
      </>
    )
  }
}

export const query = graphql`
  query($locale: String!) {
    datoCmsContactsPage(locale: { eq: $locale }) {
      title
      address
      phone
      email
    }
  }
`

const customProps = {
  localeKey: 'contacts',
}

export default withLayout(customProps)(injectIntl(Contacts))
